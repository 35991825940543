.landing {
  text-align: center;
  padding: 0 !important;

  .content {
    padding: 20px 20px 40px;
    max-width: 600px;
    margin: 0 auto;
  }

  .actions {
    padding: 10px 0;

    label {
      margin-bottom: 20px;
    }

    a {
      color: var(--primary-button-color);
      padding: 10px 15px;
      border-radius: 2px;
      margin: 10px;
      font-weight: 800;
      border: 2px solid var(--primary-button-background);

      &:hover {
        background-color: var(--primary-button-background);
        color: white;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .landing {
    .content {
      padding: 20px 10px 40px;
    }
  }
}
