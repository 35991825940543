.app {
  position: relative;
  .stylized {
    font-family: 'EB Garamond';
    font-style: italic;
  }

  .pages {
    label {
      color: inherit;
    }

    padding: 20px;
    h1 {
      font-size: 3em;
    }
    h2 {
      font-style: italic;
    }
  }
}

@media screen and (max-width: 767px) {
  .app {
    .pages {
      h1 {
        font-size: 1.5em;
      }
      h3 {
        font-size: 1em;
      }
    }
  }
}
