.grid {
  display: flex;
  flex-wrap: wrap;

  .card {
    width: 50vw;
    padding: 2vw;
    overflow: hidden;

    .card-image {
      img {
        width: 100%;
        height: auto;
      }
    }

    .meta-art {
      margin-bottom: -5px;
    }

    .meta-art,
    .meta-artist {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .meta-artist {
      color: #aaa;
      align-items: flex-start;
      line-height: 1.1;
      text-align: left;

      a {
        &:hover {
        color: var(--primary-button-background)
      }

      }

      h3 {
        width: 100%;
        margin-bottom: 0;
        display: flex;
        justify-content: space-between;
        line-height: 1.25;
        font-size: .8em;
        font-weight: 400;
        font-family: var(--normal-font);
  

        a {
          &:hover {
            color: var(--primary-button-background);
          }
        }

        .meta-type {
          text-align: right;
        }
      }
    }
  }

  .image-carousel {
    display: flex;
    overflow: hidden;
    overflow-x: auto;

    img {
      width: 50vw;
      height: auto;
      margin-right: 10px;
    }
  }
}

@media screen and (max-width: 767px) {
  .grid {
    .card {
      .meta-art {
        h2 {
          line-height: 1;
          margin-top: 5px;
        }
      }
      .meta-artist {
        h3 {
          margin-top: 5px;
          display: block;
          width: 100%;
          font-size: 14px;
          line-height: 1.1;

          a {
            font-size: 14px;
          }

          .meta-type {
            text-align: left;
            width: 100%;
          }
        }
      }
    }
  }
}
