.menu {
  position: absolute;
  top: 60px;
  background-color: whitesmoke;
  min-width: 200px;
  border-radius: 2px;
  left: 20px;
  z-index: 2;
  text-align: center;
  z-index: 3;

  img {
    height: 40px;
    margin: 20px auto 0;
  }

  menu {
    list-style: none;
    padding: 15px 20px;
    margin: 0;
    text-align: left;

    li {
      padding: 5px 0;
      a {
        width: 100%;
        cursor: pointer;
        &:hover {
          color: var(--primary-button-background);
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  menu.dropdown {
    font-size: 1.5em;
    width: 300px;
  }
}
