.artists {
  padding: 0 20px;

  label {
    color: inherit;
  }

  h2,
  p {
    margin-bottom: -5px;
    font-style: italic;
  }

  .section {
    padding: 20px 0;
    display: flex;
  }

  .artist-info {
    width: 200px;
    height: 25vh;

    p {
      font-style: normal;
      font-family: var(--normal-font);
      opacity: .5;
      font-size: .75em;
      line-height: 1.6;
    }

    h2 {
      font-size: 1em;
    }

    img {
      height: 200px;
      border-radius: 2px;
      width: 200px;
      object-fit: cover;
    }
  }

  .artist-gallery {
    width: 100%;
    height: 25vh;
    min-height: 400px;
    display: flex;
    overflow: auto;

    .artwork-image {
      margin-left: 15px;
      height: 100%;
      min-height: 400px;
      border-radius: 2px;
    }
  }
}

@media screen and (max-width: 767px) {
  .artists {
    padding: 0 2vw;

    .section {
      margin-bottom: 1.5em;
    }

    .artist-info {
      width: 100%;
      height: 60px;
      margin: 20px 0 8px;

      h2 {
        margin-top: -5px;
        margin-bottom: -7.5px;
      }

      a {
        display: flex;

        img {
          padding-right: 10px;
          border-radius: 2px;
          height: 60px;
          width: 60px;
          object-fit: cover;
        }
      }
    }

    .artist-gallery {
      width: 100%;
      height: 180px;
      display: flex;
      overflow: auto;
      min-height: 180px;

      .artwork-image {
        min-height: 180px;
        margin-left: 0;
        margin-right: 10px;
        border-radius: 2px;
      }
    }

    .section {
      padding: 0;
      display: block;
    }
  }
}
