.artwork-detail {
  position: relative;

  .content {
    padding: 40px 20px 40px;
    overflow: hidden;

    .buy-section {
      width: 100%;
      padding-right: 20px;
      max-width: 600px;

      .form {
        margin-bottom: 20px;
      }

      .btn {
        font-size: 1.25em;
        min-width: 260px;
      }

      .half {
        width: 50%;
        float: left;

        &.first {
          input {
            border-right: none;
          }
        }
      }
    }

    .image-container {
      display: flex;
      justify-content: flex-start;
      overflow: scroll;

      .main-image {
        width: 100%;
        max-width: 1200px;
        text-align: center;
        border: 10px solid #fff;
      }
    }

    .artwork-meta {
      display: flex;
      justify-content: space-between;
      padding: 0 0 40px;

      .left {
        width: 30%;

        &.full {
          width: 100%;
          max-width: 500px;
          padding-right: 40px;
        }

        h2 {
          margin-bottom: 0;
        }
      }

      .right {
        width: 70%;

        h1 {
          font-style: italic;
        }
      }

      h1 {
        font-size: 3em;
      }
    }

    h3 {
      &.label {
        font-weight: 800;
      }
    }

    .btn {
      font-size: 1.75em;

      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;

        &:hover {
          background-color: transparent;
          color: initial;
        }
      }

      .paypal {
        height: 24px;
        margin-left: 5px;
      }
    }
  }

  .suggestions {
    h4 {
      padding: 0 20px;
    }

    .grid {
      .card {
        max-width: 380px;

        .meta-art {
          h2 {
            font-size: 1.25em;
          }
        }
        .meta-artist {
          h3 {
            font-size: 1em;
            line-height: 1.25;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .artwork-detail {
    padding-bottom: 20px;
    .suggestions {
      h4 {
        margin-top: 20px;
        text-align: center;
      }
    }
    .content {
      padding: 0;

      .artwork-meta {
        display: block;
        padding-bottom: 0;

        .left,
        .right {
          width: 100%;
        }

        .left {
          padding: 20px 15px;
        }

        .right {
          padding: 0 15px 0;
        }
      }
    }
  }
}
