.artist-detail {
  text-align: center;
  padding-bottom: 5vh;

  h1 {
    font-size: 3em;
    margin-bottom: 0;
  }

  .photo {
    width: 100vw;
    height: 100vw;
    max-width: 200px;
    max-height: 200px;
    border-radius: 2px;
    margin: 10px auto 30px;
    object-fit: contain;
  }

  .background {
    width: auto;
    height: 60vh;
    max-height: 600px;
    object-fit: contain;
    margin: 10px auto 30px;
  }

  .meta {
    padding: 0 15px 15px;
    max-width: 600px;
    margin: auto;
  }
}

@media screen and (max-width: 768px) {
  .artist-detail {
    label {
      margin: 0;
    }

    .photo {
      border-radius: 0;
      margin: 0;
    }

    .background {
      width: 100%;
      height: auto;
    }

    .meta {
      p {
        margin-bottom: 5px;
      }
      padding-top: 15px;
    }
  }
}
