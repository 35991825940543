.select {
  position: relative;
  .select-box {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .icon {
      height: 15px;
    }
  }
  ul {
    list-style: none;
    padding: 0;
    margin: -11px 0 0;
    position: absolute;
    background-color: white;
    width: 100%;
    //height: 200px;
    max-height: 300px;
    overflow: scroll;
    border: 1px solid #ced4da;
    z-index: 1;

    li {
      padding: 5px 15px;
      cursor: pointer;
      list-style: none;

      &:hover {
        background-color: var(--select-background-active);
      }
    }
  }
}
