.about {
  max-width: 600px;
  margin: auto;

  h1 {
    text-align: center;
  }

  p {
    font-size: 1em;
  }

  a {
    font-weight: 800;
  }
}
