.subscribe {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h1 {
    margin-bottom: 0;
  }

  input,
  button {
    width: 300px;
    margin: auto;
  }

  button {
    margin-top: 10px;
   
  }
}
