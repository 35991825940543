.staging {
  height: 75vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  position: relative;

  .artwork-image {
    width: auto;
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    z-index: 2;
    border: 5px solid rgba(255, 255, 255, 0.25);
    border-radius: 2px;
    max-width: 600px;
  }

  .caveat {
    font-size: 8px;
    padding: 5px 10px;
    font-family: var(--normal-font);
    opacity: 0.75;
    letter-spacing: 1px;
    text-transform: uppercase;
    position: absolute;
    left: 0;
    bottom: 0;
    color: white;
  }

  .controls {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.75);

    img {
      height: 40px;
      width: auto;
      padding: 10px;
      cursor: pointer;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .staging {
    .artwork-image {
      top: 0 !important;
      right: 0 !important;
      left: 0 !important;
      width: calc(100vw - 30px);
      object-fit: contain;
      border: none;

      &.vertical {
        margin: 10px;
      }
    }
  }
}
