.hook {
  text-align: center;
  padding-bottom: 60px;

  img {
    width: 80vw;
    height: auto;
    max-width: 600px;
    margin-bottom: 10px;
  }

  .info {
    padding: 10px;
    max-width: 600px;
    margin: auto;
    text-align: left;
  }

  h5 {
    font-size: 1.25em;
  }

  table {
    margin: auto;
    text-align: left;
    margin-bottom: 20px;

    label {
      opacity: 0.5;
    }

    .first {
      padding-top: 5px;
      padding-right: 5px;
    }
  }
}
