nav {
  font-family: var(--normal-font);
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 9;

  .hide {
    opacity: 0;
    transition: 0.25s ease-in-out;

    &.show {
      opacity: 1;
    }
  }

  .logo-menu {
    cursor: pointer;
    display: flex;
    align-items: center;

    svg {
      fill: inherit;
      transition: 0.25s ease-in-out;
      &:hover {
        fill: #f58e23;
      }
    }
  }

  img {
    margin-left: 20px;
    height: 40px;

    &.icon {
      height: 20px;
      margin-right: -10px;
      //display: none;
    }
  }

  menu {
    display: flex;
    list-style: none;
    padding: 0 10px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    li {
      cursor: pointer;
      font-size: 0.8em;
      font-weight: 600;
      letter-spacing: 2px;
      text-transform: uppercase;
      margin: 0 10px;

      a {
        cursor: pointer;
        &:hover {
          color: var(--primary-button-background);
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  nav {
    justify-content: center;
    menu {
      justify-content: center;
    }
  }

  .logo-menu {
    img {
      &.icon {
        //display: block;
      }
    }
  }
}
